<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default{
    mixins: [Pie,reactiveProp],
    props: {
        datas : Object
    },
    data(){
        return {
            datacollection: Object,
            options: {
                responsive: true,
            }
        }
    },
    mounted() {
        this.fillData()
        this.renderChart(this.datacollection, this.options)
    },
    methods: {
        fillData() {
            this.datacollection = {
                labels: [this.datas['powerA'], this.datas['powerB']],
                datasets: [
                    {
                        data: [this.datas['dataA'], this.datas['dataB']],
                        backgroundColor: ['#d9534f', '#428bca']
                    },
                ],
            };
        }
    },
    watch: {
        datas() {
            this.fillData()
            this.renderChart(this.datacollection, this.options)
        },
    }
}
</script>
