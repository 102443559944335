<template>
    <div id="top" class="top">
        <!-- PC版 -->
        <div class="d-sm-block d-none pt-5 pb-5 pr-5 pl-5">
            <div class="row justify-content-center pt-4">
                <div class="col-md-7 main">
                    <!-- 一覧表示 -->
                    <div class="text-left pt-3 pb-3">
                        <span style="font-weight: 500;">最近見た話題</span>
                    </div>
                    <div>
                        <div v-for="thread in threadHistory" :key="thread.id">
                            <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                                <!-- 履歴10件まで -->
                                <div class="row pr-0 pl-0 mr-0 ml-0 mb-1">
                                    <div class="col-1 pr-0 pl-0 img_pc">
                                        <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                        <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                    </div>
                                    <div class="col-1 pr-0 pl-0 img_pc">
                                        <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                        <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                    </div>
                                    <div class="col-10 pr-0 pl-2 text-left border-bottom">
                                        <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                        <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                                        <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                        <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <Adsense />
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pt-3 pb-3 pr-3 pl-3 main">
            <!-- 一覧表示 -->
            <div class="text-left pb-3 mb-1 border-bottom">
                <span style="font-weight: 500;">最近見た話題</span>
            </div>
            <div v-for="thread in threadHistory" :key="thread.id">
                <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                    <!-- 履歴10件まで -->
                    <div class="row pr-0 pl-0 mr-0 ml-0 mb-1 border-bottom">
                        <div class="col-2 pr-0 pl-0 img_sp">
                            <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                            <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                        </div>
                        <div class="col-2 pr-0 pl-0 img_sp">
                            <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                            <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                        </div>
                        <div class="col-8 pr-0 pl-2 text-left">
                            <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                            <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                            <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                            <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Adsense from "@/components/adsense/adsense.vue"
import "../../firebase"
import { getFirestore, collection, getDoc, doc } from 'firebase/firestore'
const db = getFirestore();
const threadRef = collection(db, "thread");

export default{
    components: {
        Adsense,
    },
    data(){
        return{
            threadHistoryIds: this.$store.getters['history/threadHistory'], //履歴id最新10件までvueX経由で取得
            threadHistory: [] //履歴最新10件までスレッド情報格納
        }
    },
    mounted: function(){
        this.getThreadInfo()
        //[SEO対策]タイトル・説明変更
        document.title = "履歴 - さくらんぼちゃんねる"
        var description = "新開発!2つの対立勢力を可視化しながら議論する全く新しい匿名比較掲示板です。あれとこれ、どっち？賛成？反対？好き？嫌い？なんでも自由に気軽に議論しよう!"
        document.querySelector('meta[name="description"]').setAttribute("content", description)
        document.querySelector('meta[name="keywords"]').setAttribute("content", "さくらんぼちゃんねる,比較掲示板,匿名掲示板")
    },
    // スレッド取得(10件)
    methods:{
        getThreadInfo: async function(){
            for(let i = 0; i < 10; i++){
                var docRef = doc(db, "thread", this.threadHistoryIds[i])
                var docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.threadHistory.push({
                        created_at : docSnap.data().created_at,
                        title : docSnap.data().title,
                        category : docSnap.data().category,
                        id : docSnap.data().id,
                        neutral : docSnap.data().neutral,
                        response : docSnap.data().response,
                        power1 : docSnap.data().power1,
                        power2 : docSnap.data().power2,
                        power1_img_url : docSnap.data().power1_img_url,
                        power2_img_url : docSnap.data().power2_img_url
                    })
                } else {
                }
            }
        },
    }
}
</script>

<style scoped>
    #top{
        background: #EEEEEE;
    }
    .main{
        background: #FFFFFF;
    }
    a:link, a:visited, a:hover, a:active {
        color: #000000;
        text-decoration:none; 
    }
    .img_sp img{
        width: 16vw;
        height: 16vw;
        object-fit: cover;
    }
    .img_pc img{
        width: 4.2vw;
        height: 4.2vw;
        object-fit: cover;
    }
    .img_sp_top img{
        width: 50vw;
        height: 50vw;
        object-fit: cover;
    }
    .img_pc_top img{
        width: 8.4vw;
        height: 8.4vw;
        object-fit: cover;
    }
</style>

