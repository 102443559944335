<template>
  <div id="app">
    <!-- PC/SPナビバー -->
    <div class="d-sm-block d-none">
      <NavPc/>
    </div>
    <div class="d-sm-none d-block shadow-sm">
      <NavSp/>
    </div>

    <!-- 遷移設定 -->
    <div class="app-content ease">
      <transition
        mode="out-in"
        name="custom-classes-transition"
            :duration="transition.duration"
            :leave-active-class="transition.leave"
            :enter-active-class="transition.enter"
      >
        <router-view/>
      </transition>
    </div>
  </div>
</template>


<script>
import NavSp from '@/components/nav/navSp.vue'
import NavPc from '@/components/nav/navPc.vue'
export default {
  components: {
    NavSp,
    NavPc
  },
  watch: {
    $route() {
      this.transition.leave = 'animate__animated animate__fadeOut';
      this.transition.enter = 'animate__animated animate__fadeIn';
    },
  },
  data() {
    return {
      transition: {
        enter: '',
        leave: '',
        duration: 100,
      },
    };
  },
}
</script>


<style>
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  cursor:pointer;
}

#app *:focus {
  outline: none;
}

*:focus {
  outline: none;
}

#nav {
  padding: 3px;
}

#nav a {
  font-weight: bold;
  color: #000000;
}

#nav a.router-link-exact-active {
  color: rgb(165, 37, 37);
}
</style>
