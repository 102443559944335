import Vue from 'vue'
import Vuex from 'vuex'
import historyX from './modules/historyX'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    history: historyX,
  },
  //vuexをsessionStorageに保存
  plugins: [createPersistedState(
    {
    key: 'cherrychannel',
    paths: [
    'history',
    ],
    storage: window.sessionStorage
    }
  )]
})
