<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default{
    mixins: [Bar,reactiveProp],
    props: {
        datas : Object
    },
    data(){
        return {
            datacollection: Object,
            options: {
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            stepSize: 1
                        }
                    }]
                },
            }
        }
    },
    mounted() {
        this.fillData()
        this.renderChart(this.datacollection, this.options)
    },
    methods: {
        fillData() {
            this.datacollection = {
                labels: ["10代", "20代", "30代", "40代", "50代", "60以上"],
                datasets: [
                    {
                        label: this.datas['powerA'],
                        data: this.datas['dataA'],
                        backgroundColor: ['#d9534f','#d9534f','#d9534f','#d9534f','#d9534f','#d9534f']
                    },
                    {
                        label: this.datas['powerB'],
                        data: this.datas['dataB'],
                        backgroundColor: ['#428bca','#428bca','#428bca','#428bca','#428bca','#428bca']
                    }
                ],
            };
        }
    },
    watch: {
        datas() {
            this.fillData()
            this.renderChart(this.datacollection, this.options)
        },
    }
}
</script>
