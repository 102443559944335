<template>
    <div id="search" class="search">
        <!-- PC版 -->
        <div class="d-sm-block d-none ">
            <div class="row justify-content-center pt-4">
                <div class="col-md-7">
                    <div class=" pt-5 pb-5 pr-5 pl-5 main">
                        <div class="text-left pb-3">
                            <span style="font-weight: 500;">スレッドを検索する</span>
                        </div>
                        <!-- カテゴリ選択 -->
                        <div @click="setCategory('全ジャンル')" class="col-2 pr-1 pl-1 mb-1 border" style="font-size: 0.8rem;" v-bind:class="{'border-danger': categoryType=='全ジャンル'}">
                            全ジャンル
                        </div>
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-3">
                            <div v-for="category in CONST.category" :key="category.key" style="font-size: 0.8rem;">
                                <div @click="setCategory(category.value)" class="col pr-1 pl-1 mb-1 border" v-bind:class="{'border-danger': categoryType==category.value}">
                                    {{category.value}}
                                </div>
                            </div>
                        </div>
                        <!-- 検索順選択 -->
                        <small class="text-muted">並び順</small>
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-2">
                            <div @click="setSearchMethod('popular')" class="col-3 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='popular'}">
                                <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">注目順</p>
                            </div>
                            <div @click="setSearchMethod('new')" class="col-3 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='new'}">
                                <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">新着順</p>
                            </div>
                            <div @click="setSearchMethod('response')" class="col-5 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='response'}">
                                <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">コメント多い順</p>
                            </div>
                        </div>
                        <!-- 検索窓 -->
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-3">
                            <div class="col-10 pr-0 pl-0">
                                <input class="form-control" type="search" placeholder="キーワード" aria-label="Search" v-model="keyWord">
                            </div>
                            <div class="col-2 pr-0 pl-0">
                                <button @click="getThreads()" class="btn btn-outline-danger" type="submit">検索</button>
                            </div>
                        </div>
                        <!-- 検索結果 -->
                        <p class="mb-3">{{resultCount}}件の検索結果</p>
                        <div v-for="thread in resultThreads" :key="thread.id">
                            <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                                <!-- 1~5件目 -->
                                <div v-if="thread.order <= 5" class="row pr-0 pl-0 mr-0 ml-0 mb-1">
                                    <div class="col-1 pr-0 pl-0 img_pc">
                                        <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                        <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                    </div>
                                    <div class="col-1 pr-0 pl-0 img_pc">
                                        <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                        <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                    </div>
                                    <div class="col-10 pr-0 pl-2 text-left border-bottom">
                                        <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                        <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                                        <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                        <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                                    </div>
                                </div>
                                <!-- 5件目以降 -->
                                <div v-if="thread.order > 5" class="text-left border-bottom">
                                    <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                    <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                                    <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                    <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <Adsense />
                    <div class="pt-3">
                        <CreateThread />
                    </div>
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pb-3 pr-3 pl-3 pt-3 main">
            <div class="">
                <div class="text-left pb-3">
                    <span style="font-weight: 500;">スレッドを検索する</span>
                </div>
                <!-- カテゴリ選択 -->
                <div @click="setCategory('全ジャンル')" class="col-3 pr-1 pl-1 mb-1 border" style="font-size: 0.8rem;" v-bind:class="{'border-danger': categoryType=='全ジャンル'}">
                    全ジャンル
                </div>
                <div class="row pr-0 pl-0 mr-0 ml-0 pb-2">
                    <div v-for="category in CONST.category" :key="category.key" style="font-size: 0.8rem;">
                        <div @click="setCategory(category.value)" class="col pr-1 pl-1 mb-1 border" v-bind:class="{'border-danger': categoryType==category.value}">
                            {{category.value}}
                        </div>
                    </div>
                </div>
                <!-- 検索順選択 -->
                <small class="text-muted">並び順</small>
                <div class="row pr-0 pl-0 mr-0 ml-0 pb-2">
                    <div @click="setSearchMethod('popular')" class="col-3 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='popular'}">
                        <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">注目順</p>
                    </div>
                    <div @click="setSearchMethod('new')" class="col-3 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='new'}">
                        <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">新着順</p>
                    </div>
                    <div @click="setSearchMethod('response')" class="col-5 pr-0 pl-0 mr-1 border" v-bind:class="{'border-danger': searchMethod=='response'}">
                        <p class="mb-0" style="padding: 0.3rem; font-size: 0.8rem;">コメント多い順</p>
                    </div>
                </div>
                <!-- 検索窓 -->
                <div class="row pr-0 pl-0 mr-0 ml-0 pb-3">
                    <div class="col-9 pr-0 pl-0">
                        <input class="form-control" type="search" placeholder="キーワード" aria-label="Search" v-model="keyWord">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <button @click="getThreads()" class="btn btn-outline-danger" type="submit">検索</button>
                    </div>
                </div>
                <!-- 検索結果 -->
                <p class="mb-3">{{resultCount}}件の検索結果</p>
                <div v-for="thread in resultThreads" :key="thread.id">
                    <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                        <!-- 1~5件目 -->
                        <div v-if="thread.order <= 5" class="row pr-0 pl-0 mr-0 ml-0 mb-1 border-bottom">
                            <div class="col-2 pr-0 pl-0 img_sp">
                                <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-2 pr-0 pl-0 img_sp">
                                <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-8 pr-0 pl-2 text-left">
                                <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                                <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                            </div>
                        </div>
                        <!-- 5件目以降 -->
                        <div v-if="thread.order > 5" class="text-left border-bottom">
                            <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                            <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                            <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                            <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONST from "@/assets/const.json"
import CreateThread  from "@/components/home/createThread.vue"
import Adsense      from "@/components/adsense/adsense.vue"
import "../firebase"
import { getFirestore, collection, getDocs, orderBy, limit, query, where } from 'firebase/firestore'
const db = getFirestore();
const threadRef = collection(db, "thread");

export default{
    name: 'Search',
    components: {
        CreateThread,
        Adsense
    },
    data(){
        return{
            CONST : CONST,
            keyWord : '',
            categoryType : '全ジャンル',
            searchMethod : 'popular',
            resultThreads : [],
            resultCount : 0,
        }
    },
    mounted: function(){
        //[SEO対策]タイトル・説明変更
        document.title = "検索 - さくらんぼちゃんねる"
        var description = "新開発!2つの対立勢力を可視化しながら議論する全く新しい匿名比較掲示板です。あれとこれ、どっち？賛成？反対？好き？嫌い？なんでも自由に気軽に議論しよう!"
        document.querySelector('meta[name="description"]').setAttribute("content", description)
        document.querySelector('meta[name="keywords"]').setAttribute("content", "さくらんぼちゃんねる,比較掲示板,匿名掲示板")
    },
    methods : {
        //カテゴリ選択
        setCategory : function(category){
            this.categoryType = category
        },
        //表示順選択
        setSearchMethod : function(method){
            this.searchMethod = method
        },
        //スレッド検索&取得
        getThreads: async function(){
            this.resultThreads = []
            this.resultCount = 0
            /*
                検索用クエリ(SQL)生成
                条件1 : カテゴリー
                条件2 : キーワード  => (スレタイ,勢力1,勢力2)を含むか
                条件3 : 並び順
            */
            //条件1 : カテゴリー
            var querySnapshot
            if(this.categoryType == '全ジャンル'){
                querySnapshot = await getDocs(query(threadRef))
            } else {
                querySnapshot = await getDocs(query(threadRef, where("category", "==", this.categoryType)))
            }

            //検索 + 条件2 : キーワード(スレタイ,勢力1,勢力2に含む結果を抽出)
                querySnapshot.forEach((doc) => {
                    if (doc.data().title.indexOf(this.keyWord) !== -1 || doc.data().power1.indexOf(this.keyWord) !== -1 || doc.data().power2.indexOf(this.keyWord) !== -1) {
                        this.resultThreads.push({
                            created_at : doc.data().created_at,
                            created_unix : doc.data().created_unix,
                            title : doc.data().title,
                            category : doc.data().category,
                            id : doc.data().id,
                            neutral : doc.data().neutral,
                            response : doc.data().response,
                            power1 : doc.data().power1,
                            power2 : doc.data().power2,
                            power1_img_url : doc.data().power1_img_url,
                            power2_img_url : doc.data().power2_img_url,
                        })
                        this.resultCount = this.resultCount + 1
                    } 
            })

            //検索結果並べ替え 条件3 : 並び順
            if(this.searchMethod == 'popular'){
                //人気順
            }
            if(this.searchMethod == 'new'){
                this.resultThreads.sort((a, b) => b['created_unix'] - a['created_unix']);
            }
            if(this.searchMethod == 'response'){
                this.resultThreads.sort((a, b) => b['response'] - a['response']);
            }
            //表示順用
            var count = 1
            this.resultThreads.forEach( function( item ) {
                item['order'] = count
                count = count + 1
            });
        }
    },
}
</script>

<style scoped>
    #search{
        background: #EEEEEE;
    }
    .main{
        background: #FFFFFF;
    }
    a:link, a:visited, a:hover, a:active {
        color: #000000;
        text-decoration:none; 
    }
    .img_sp img{
        width: 16vw;
        height: 16vw;
        object-fit: cover;
    }
    .img_pc img{
        width: 4.2vw;
        height: 4.2vw;
        object-fit: cover;
    }
    .img_sp_top img{
        width: 50vw;
        height: 50vw;
        object-fit: cover;
    }
    .img_pc_top img{
        width: 8.4vw;
        height: 8.4vw;
        object-fit: cover;
    }
</style>
