/*
    直近見たスレッドID保存
*/

export default {
    namespaced: true,
    state : {
        threadHistory : []
    },
    getters : {
        //全履歴ID取得
        threadHistory: state => state.threadHistory,
        //最新履歴1件取得
        getRecent : (state) => state.threadHistory[0],
        //最新履歴10件取得
        getRecent10 : (state) => {
            var historyArray = []
            for (let i = 0; i < 10; i++) {
                historyArray.push(state.threadHistory[i])
            }
            return historyArray
        },
    },
    mutations : {
        //ID1件を[]先頭に追加(既にidが登録されてれば削除してから先頭に追加)
        pushHistory(state, id) {
            if(state.threadHistory.includes(id)){
                var idx = state.threadHistory.indexOf(id)
                if(idx >= 0){
                    state.threadHistory.splice(idx, 1)
                }
            }
            state.threadHistory.unshift(id)
        },
        //ID全件削除
        deleteHistory(state) {
            state.threadHistory = []
        }
    }
}
