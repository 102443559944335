import Vue from 'vue'
import VueRouter from 'vue-router'
import Home      from '../views/Home.vue'
import Search    from '../views/Search.vue'
import History    from '../views/History.vue'
import Thread    from '../views/Thread.vue'
import Response  from '../views/Response.vue'
import About    from '../views/About.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      index: 100,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      index: 200,
    },
  },
  {
    path: '/thread/:id',
    name: 'Thread',
    component: Thread,
    props: true,
    meta: {
      index: 300,
    },
  },
  {
    path: '/response/:id/:number',
    name: 'Response',
    component: Response,
    props: true,
    meta: {
      index: 301,
    },
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    props: true,
    meta: {
      index: 400,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      index: 500,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
