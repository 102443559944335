<template>
    <div id="top" class="top">
        <!-- PC版 -->
        <div class="d-sm-block d-none pt-2 pb-2">
            <!-- 一覧表示 -->
            <div class="text-left pb-3">
                <span style="font-weight: 600;">注目の話題</span>
            </div>
            <div>
                <div v-for="thread in threads" :key="thread.id">
                    <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                        <!-- 注目1位 -->
                        <div v-if="thread.ranking == 1" class="row pr-0 pl-0 mr-0 ml-0 mb-1">
                            <div class="col-2 pr-0 pl-0 img_pc_top">
                                <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-2 pr-0 pl-0 img_pc_top">
                                <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-8 pr-0 pl-2 text-left border-bottom">
                                <p class="mb-0" style="font-size: 1.0rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                <span class="text-muted" style="font-size: 0.8rem;">{{thread.created_at}} </span>
                                <span style="font-size: 0.8rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                <span style="font-size: 0.8rem; font-weight: 500;">{{thread.category}} </span>
                            </div>
                        </div>
                        <!-- 注目2位~6位 -->
                        <div v-if="thread.ranking != 1" class="row pr-0 pl-0 mr-0 ml-0 mb-1">
                            <div class="col-1 pr-0 pl-0 img_pc">
                                <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-1 pr-0 pl-0 img_pc">
                                <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                            </div>
                            <div class="col-10 pr-0 pl-2 text-left border-bottom">
                                <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                                <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                                <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                                <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pt-3 pb-3">
            <!-- 一覧表示 -->
            <div class="text-left pb-3">
                <span style="font-weight: 500;">注目の話題</span>
            </div>

            <div v-for="thread in threads" :key="thread.id">
                <router-link :to="{ name: 'Thread', params: {id: thread.id}}">
                    <!-- 注目1位 -->
                    <div v-if="thread.ranking == 1" class="text-left mb-1 border-bottom border-top">
                        <p class="mb-0 pt-1" style="font-size: 1.0rem; font-weight: 500;">{{thread.title}}</p>
                        <span class="text-muted" style="font-size: 0.8rem;">{{thread.created_at}} </span>
                        <span style="font-size: 0.8rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                        <span style="font-size: 0.8rem; font-weight: 500;">{{thread.category}} </span>
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                            <div class="col-6 pr-0 pl-0 img_sp_top">
                                <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                                <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img img-fluid border">
                            </div>
                            <div class="col-6 pr-0 pl-0 img_sp_top">
                                <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                                <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img img-fluid border">
                            </div>
                        </div>
                    </div>
                    <!-- 注目2位~6位 -->
                    <div v-if="thread.ranking != 1" class="row pr-0 pl-0 mr-0 ml-0 mb-1 border-bottom">
                        <div class="col-2 pr-0 pl-0 img_sp">
                            <img v-if="thread.power1_img_url" :src="thread.power1_img_url" class="img-fluid border">
                            <img v-if="!thread.power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                        </div>
                        <div class="col-2 pr-0 pl-0 img_sp">
                            <img v-if="thread.power2_img_url" :src="thread.power2_img_url" class="img-fluid border">
                            <img v-if="!thread.power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                        </div>
                        <div class="col-8 pr-0 pl-2 text-left">
                            <p class="mb-0" style="font-size: 0.75rem; font-weight: 500; padding-top: 0.2rem;">{{thread.title}}</p>
                            <span class="text-muted" style="font-size: 0.6rem;">{{thread.created_at}} </span>
                            <span style="font-size: 0.6rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{thread.response}} </span>
                            <span style="font-size: 0.6rem; font-weight: 500;">{{thread.category}} </span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import "../../firebase"
import { getFirestore, collection, getDocs, orderBy, limit, query } from 'firebase/firestore'
const db = getFirestore();
const threadRef = collection(db, "thread");

export default{
    data(){
        return{
            threads:[],
            ranking: 1,
        }
    },
    mounted: function(){
        this.getThreads()
    },
    // スレッド取得(6件)
    methods:{
        getThreads: async function(){
            const querySnapshot = await getDocs(query(threadRef, limit(6)))
                querySnapshot.forEach((doc) => {
                this.threads.push({
                    ranking : this.ranking,
                    created_at : doc.data().created_at,
                    created_unix : doc.data().created_unix,
                    title : doc.data().title,
                    category : doc.data().category,
                    id : doc.data().id,
                    neutral : doc.data().neutral,
                    response : doc.data().response,
                    power1 : doc.data().power1,
                    power2 : doc.data().power2,
                    power1_img_url : doc.data().power1_img_url,
                    power2_img_url : doc.data().power2_img_url
                })
                this.ranking = this.ranking + 1
            });
        }
    }
}
</script>

<style scoped>
    #top{
        background: #FFFFFF;
    }
    a:link, a:visited, a:hover, a:active {
        color: #000000;
        text-decoration:none; 
    }
    .img_sp img{
        width: 16vw;
        height: 16vw;
        object-fit: cover;
    }
    .img_pc img{
        width: 4.2vw;
        height: 4.2vw;
        object-fit: cover;
    }
    .img_sp_top img{
        width: 50vw;
        height: 50vw;
        object-fit: cover;
    }
    .img_pc_top img{
        width: 8.4vw;
        height: 8.4vw;
        object-fit: cover;
    }
</style>
