//Firebase import
import firebase from "firebase/compat/app";

//Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyAgQNdRyW_tHxZCh8XALdB-aOrpiPlXkvA",
    authDomain: "cherrychannel-web.firebaseapp.com",
    projectId: "cherrychannel-web",
    storageBucket: "cherrychannel-web.appspot.com",
    messagingSenderId: "524527440553",
    appId: "1:524527440553:web:b0051ff60f5b4b54620fb8",
    measurementId: "G-BN7628WBM5"
};

//Initialize Firebase
if (!firebase.apps.length) {
firebase.initializeApp(firebaseConfig);
}

//Export firebase
export{
    firebase
}
