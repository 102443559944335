<template>
    <div id="create" class="create">
        <!-- PC版 -->
        <div class="d-sm-block d-none pt-3 pb-3 pr-3 pl-3">
            <div class="text-left">
                <span style="font-weight: 500;">新規スレッド作成</span>
            </div>
            <!-- ジャンル -->
            <div class="text-left">
                <span class="mb-0 text-left" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge></span>
                <span class="pb-2 mb-0 text-left" style="font-size:0.8rem;"> ジャンル</span>
                <b-dropdown class="selectArea" :text="category" block variant="outline-dark">
                    <div v-for="category in CONST.category" :key="category.key" style="font-size: 0.8rem;">
                        <b-dropdown-item @click="setCategory(category.value)">{{category.value}}</b-dropdown-item>
                    </div>
                </b-dropdown>
            </div>
            <!-- スレタイ -->
            <div class="text-left">
                <label class="mb-0" for="title" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge> スレタイ(50文字以内)</label>
                <input id="title" type="text" v-model="title"  class="form-control" name="title" required :placeholder="title">
            </div>
            <!-- 比較対象/画像 -->
            <div class="text-left">
                <label class="mb-0" for="power" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge> 比較対象</label><br>
                <span class="text-danger" style="font-size:0.5rem;">※ 画像タップでイメージ設定(推奨)/jpg,jpeg,png</span>
                <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                    <!-- 勢力1 -->
                    <div class="col-6 pr-1">
                        <div class="img_set" @click="imgTap1()">
                            <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="image1 == null">
                            <img :src="image1" alt="img" class="img-fluid border" v-if="image1!=null">
                            <input type="file" ref="file1"  style="display: none;" @change="setImage1" accept="image/jpeg, image/jpg, image/png">
                        </div>
                        <input id="power1" type="text" v-model="power1"  class="form-control mt-1" name="power1" required :placeholder="power1">
                    </div>
                    <!-- 勢力2 -->
                    <div class="col-6 pl-1">
                        <div class="img_set" @click="imgTap2()">
                            <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="image2 == null">
                            <img :src="image2" alt="img" class="img-fluid border" v-if="image2!=null">
                            <input type="file" ref="file2"  style="display: none;" @change="setImage2" accept="image/jpeg, image/jpg, image/png">
                        </div>
                        <input id="power2" type="text" v-model="power2"  class="form-control mt-1" name="power2" required :placeholder="power2">
                    </div>
                </div>
            </div>
            <!-- オプション -->
            <div class="text-left pb-2">
                <label class="mb-0" for="option" style="font-size: 0.8rem;"><b-badge variant="secondary">任意</b-badge> オプション設定</label>
                <div class="custom-control custom-switch text-left">
                    <input type="checkbox" class="custom-control-input" id="customSwitch-match" v-model="neutral">
                    <label class="custom-control-label" for="customSwitch-match" style="font-size: 0.8rem; padding-top:0.24rem;">"中立"の立場を許可</label>
                </div>
                <div class="custom-control custom-switch text-left">
                    <input type="checkbox" class="custom-control-input" id="customSwitch-debate" v-model="debate">
                    <label class="custom-control-label" for="customSwitch-debate" style="font-size: 0.8rem; padding-top:0.24rem;">"真剣討論"モード(勝敗あり)</label>
                </div>
                <div class="custom-control custom-switch text-left">
                    <input type="checkbox" class="custom-control-input" id="customSwitch-time72" v-model="time72">
                    <label class="custom-control-label" for="customSwitch-time72" style="font-size: 0.8rem; padding-top:0.24rem;">"72時間限定"モード</label>
                </div>
            </div>
            <!-- 作成ボタン -->
            <p class="text-danger">{{error_msg}}</p>
            <button class="pt-2 btn btn-create btn-block" @click="createThread()">スレッドを作成</button>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pt-3 pb-3">
            <div class="text-left pb-2">
                <span style="font-weight: 500;">新規スレッド作成</span>
            </div>
            <div v-if="creating">
                <!-- ジャンル -->
                <div class="text-left">
                    <span class="mb-0 text-left" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge></span>
                    <span class="pb-2 mb-0 text-left" style="font-size:0.8rem;"> ジャンル</span>
                    <b-dropdown class="selectArea" :text="category" block variant="outline-dark">
                        <div v-for="category in CONST.category" :key="category.key" style="font-size: 0.8rem;">
                            <b-dropdown-item @click="setCategory(category.value)">{{category.value}}</b-dropdown-item>
                        </div>
                    </b-dropdown>
                </div>
                <!-- スレタイ -->
                <div class="text-left">
                    <label class="mb-0" for="title" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge> スレタイ(50文字以内)</label>
                    <input id="title" type="text" v-model="title"  class="form-control" name="title" required :placeholder="title">
                </div>
                <!-- 比較対象/画像 -->
                <div class="text-left">
                    <label class="mb-0" for="power" style="font-size: 0.8rem;"><b-badge variant="danger">必須</b-badge> 比較対象</label><br>
                    <span class="text-danger" style="font-size:0.5rem;">※ 画像タップでイメージ設定(推奨)/jpg,jpeg,png</span>
                    <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                        <!-- 勢力1 -->
                        <div class="col-6 pr-1">
                            <div class="img_set" @click="imgTap1()">
                                <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="image1 == null">
                                <img :src="image1" alt="img" class="img-fluid border" v-if="image1!=null">
                                <input type="file" ref="file1"  style="display: none;" @change="setImage1" accept="image/jpeg, image/jpg, image/png">
                            </div>
                            <input id="power1" type="text" v-model="power1"  class="form-control mt-1" name="power1" required :placeholder="power1">
                        </div>
                        
                        <!-- 勢力2 -->
                        <div class="col-6 pl-1">
                            <div class="img_set" @click="imgTap2()">
                                <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="image2 == null">
                                <img :src="image2" alt="img" class="img-fluid border" v-if="image2!=null">
                                <input type="file" ref="file2"  style="display: none;" @change="setImage2" accept="image/jpeg, image/jpg, image/png">
                            </div>
                            <input id="power2" type="text" v-model="power2"  class="form-control mt-1" name="power2" required :placeholder="power2">
                        </div>
                    </div>
                </div>
                <!-- オプション -->
                <div class="text-left pb-2">
                    <label class="mb-0" for="option" style="font-size: 0.8rem;"><b-badge variant="secondary">任意</b-badge> オプション設定</label>
                    <div class="custom-control custom-switch text-left">
                        <input type="checkbox" class="custom-control-input" id="customSwitch-match-sp" v-model="neutral">
                        <label class="custom-control-label" for="customSwitch-match-sp" style="font-size: 0.8rem; padding-top:0.24rem;">"中立"の立場を許可する</label>
                    </div>
                    <div class="custom-control custom-switch text-left">
                        <input type="checkbox" class="custom-control-input" id="customSwitch-debate-sp" v-model="debate">
                        <label class="custom-control-label" for="customSwitch-debate-sp" style="font-size: 0.8rem; padding-top:0.24rem;">"真剣討論"モード(勝敗あり)</label>
                    </div>
                    <div class="custom-control custom-switch text-left">
                        <input type="checkbox" class="custom-control-input" id="customSwitch-time72-sp" v-model="time72">
                        <label class="custom-control-label" for="customSwitch-time72-sp" style="font-size: 0.8rem; padding-top:0.24rem;">"72時間限定"モード</label>
                    </div>
                </div>
                <!-- 作成ボタン -->
                <p class="text-danger">{{error_msg}}</p>
                <button class="pt-2 btn btn-create btn-block" @click="createThread()">スレッドを作成</button>
            </div>
            <button v-if="!creating" class="btn btn-create btn-block" @click="creatingMode()"><i class="bi bi-plus"></i> 新規スレッド作成</button>
        </div>
    </div>
</template>

<script>
import CONST from "@/assets/const.json"
import "../../firebase"
import { getFirestore, doc, collection, setDoc} from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const threadRef = collection(db, "thread");
const storage = getStorage();

export default{
    data() {
        return {
            CONST : CONST,
            creating : false,
            title : '',
            category : '未選択',
            power1: 'A',
            power2: 'B',
            image1: null,
            image2: null,
            neutral: false,
            debate: false,
            time72: false,
            error_msg: ''
        };
    },
    methods:{
        creatingMode: function(){
            this.creating = true
        },
        setCategory: function(input){
            this.category = input
        },
        //画像タップ時(->input->file起動)
        imgTap1: function() {
            this.$refs.file1.click();
        },
        imgTap2: function() {
            this.$refs.file2.click();
        },
        async setImage1() {
            const files = this.$refs.file1;
            const fileImg = files.files[0];
            if (fileImg.type.startsWith("image/")) {
                this.image1 = window.URL.createObjectURL(fileImg);
            }
        },
        async setImage2() {
            const files = this.$refs.file2;
            const fileImg = files.files[0];
            if (fileImg.type.startsWith("image/")) {
                this.image2 = window.URL.createObjectURL(fileImg);
            }
        },
        // 日時取得
        createDate: function(){
            var date = new Date()
            var year = String(date.getFullYear())
            var month = String(date.getMonth() + 1 )
            var day = String(date.getDate())
            var hour = String(date.getHours())
            var min = String(date.getMinutes())
            var sec = String(date.getSeconds())
            //var millisec = String(date.getMilliseconds())
            var created = year + '/' + month + '/' + day + '/' + hour + ':' + min + ':' + sec
            return created
            
        },
        // ランダムID(16桁+unixTime)生成 = ドキュメントIDは生成日順
        createId: function(){
            var date = new Date()
            const SOURCE = "abcdefghijklmnopqrstuvwxyz0123456789"
            let result = ''
            for(let i=0; i<16; i++){
                result += SOURCE[Math.floor(Math.random() * SOURCE.length)];
            }
            return  date.getTime() + result
        },
        // スレッド作成
        createThread: function(){
            this.error_msg = ''
            //バリデーション(HTMLタグ/SQL)
            /*
                HTMLタグを検出...<div,<span,<br,<meta,<title,<p,<h1,<h2,<h3,<h4,<h5,<h6,<section,<formなど
                SQL文法を検出...SELECT,DELETE,UPDATE,INSERT(大文字のみ)
            */
            if ( this.title.indexOf('<div>') != -1 || this.title.indexOf('<span>') != -1 || this.title.indexOf('<br>') != -1 || this.title.indexOf('<meta>') != -1 || 
                this.title.indexOf('<title>') != -1 || this.title.indexOf('<p>') != -1 || this.title.indexOf('<section>') != -1 || this.title.indexOf('<form>') != -1 || 
                this.title.indexOf('<h1>') != -1 || this.title.indexOf('<h2>') != -1 || this.title.indexOf('<h3>') != -1 || this.title.indexOf('<h4>') != -1 || 
                this.title.indexOf('<h5>') != -1 || this.title.indexOf('<h6>') != -1) {
                this.error_msg = 'このタイトルでは作成できません'
                return
            }
            if (this.title.indexOf('SELECT') != -1 || this.title.indexOf('DELETE') != -1 || this.title.indexOf('UPDATE') != -1 || this.title.indexOf('INSERT') != -1) {
                this.error_msg = 'このタイトルでは作成できません'
                return
            }
            //バリデーション
            if(this.category == '未選択'){
                this.error_msg = 'カテゴリーを選択してください'
                return
            }
            if(!this.title){
                this.error_msg = 'スレタイを設定してください'
                return
            }
            if(!this.power1 || !this.power2){
                this.error_msg = '比較対象を設定してください'
                return
            }
            //DB作成
            var Unixdate = new Date().getTime()
            const ID = this.createId()
            setDoc(doc(threadRef, ID), {
                created_at: this.createDate(),
                created_unix : Unixdate,
                id: ID,
                title: this.title,
                category: this.category,
                power1: this.power1,
                power2: this.power2,
                power1_img_url: '',
                power2_img_url: '',
                neutral: this.neutral,
                debate: this.debate,
                time72: this.time72,
                response: 0,
            });
            //画像投稿&firestore画像ダウンロード先URL設定
            const files1 = this.$refs.file1;
            const files2 = this.$refs.file2;
            //storage参照作成
            const imageRef1 = ref(storage, 'threadImages/' + ID + '/power1');
            const imageRef2 = ref(storage, 'threadImages/' + ID + '/power2');
            uploadBytes(imageRef1, files1.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setDoc(doc(threadRef, ID), {
                        power1_img_url : url,
                    },{ merge: true });
                });
            });
            uploadBytes(imageRef2, files2.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setDoc(doc(threadRef, ID), {
                        power2_img_url : url,
                    },{ merge: true });
                });
            });
            this.$router.push('./search');
        }
    }
}
</script>

<style scoped>
    #create{
        background: #FFFFFF;
    }
    .create .btn-create{
    background-color: rgb(165, 37, 37);
    color: white;
    }
    /* ドロップダウンの上下幅 */
    .selectArea /deep/ .dropdown-menu {
        max-height: 250px;
        overflow-y: auto;
    }
</style>
