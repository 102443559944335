<template>
    <div id="thread" class="thread">
        <!-- PC版 -->
        <div class="d-sm-block d-none">
            <div class="row justify-content-center pt-4">
                <div class="col-md-7">
                    <div class="main pr-2 pl-2 pt-2 pb-2 mb-3">
                        <!-- 元コメント -->
                        <div class="text-left border-bottom mb-3">
                            <span style="font-size: 0.8rem;">{{comment.number}}.</span>
                            <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{comment.name}}</span>
                            <span class="text-muted" style="font-size: 0.8rem;"> {{comment.created_at}} </span>
                            <span v-if="comment.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                            <span v-if="comment.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                            <span v-if="comment.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                            <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestSP @click="setDeleteRequest(comment)"> [/通報]</span>
                            <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight:600;">{{comment.text}}</p>
                        </div>
                        <!-- 返信 -->
                        <p class="border-bottom">返信</p>
                        <div v-for="res in comment.from" :key="res.number">
                            <div class="text-left border-bottom mb-3">
                                <span style="font-size: 0.8rem;">{{res.number}}.</span>
                                <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{res.name}}</span>
                                <span class="text-muted" style="font-size: 0.8rem;"> {{res.created_at}} </span>
                                <span v-if="res.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                                <span v-if="res.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                                <span v-if="res.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                                <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestSP @click="setDeleteRequest(res)"> [/通報]</span>
                                <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight:600;">{{res.text}}</p>
                            </div>
                        </div>
                        <!-- 戻るボタン -->　
                        <div @click="$router.back()">
                            <i class="bi bi-arrow-return-left">戻る</i>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="mb-3 main">
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block">
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3">
                <!-- 元コメント -->
                <div class="text-left border-bottom mb-3">
                    <span style="font-size: 0.8rem;">{{comment.number}}.</span>
                    <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{comment.name}}</span>
                    <span class="text-muted" style="font-size: 0.8rem;"> {{comment.created_at}} </span>
                    <span v-if="comment.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                    <span v-if="comment.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                    <span v-if="comment.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                    <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestSP @click="setDeleteRequest(comment)"> [/通報]</span>
                    <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight:500;">{{comment.text}}</p>
                </div>
                <!-- 返信 -->
                <p class="border-bottom">返信</p>
                <div v-for="res in comment.from" :key="res.number">
                    <div class="text-left border-bottom mb-3">
                        <span style="font-size: 0.8rem;">{{res.number}}.</span>
                        <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{res.name}}</span>
                        <span class="text-muted" style="font-size: 0.8rem;"> {{res.created_at}} </span>
                        <span v-if="res.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                        <span v-if="res.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                        <span v-if="res.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                        <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestSP @click="setDeleteRequest(res)"> [/通報]</span>
                        <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight:500;">{{res.text}}</p>
                    </div>
                </div>
                <!-- 戻るボタン -->　
                <div @click="$router.back()">
                    <i class="bi bi-arrow-return-left">戻る</i>
                </div>
            </div>

            <!-- 削除依頼モーダルSP -->
            <b-modal id="deleteRequestSP" ref="deleteRequestSP-modal" class="deleteRequestSP" hide-header hide-footer>
                <p class="text-danger text-center mb-2"><i class="bi bi-exclamation-triangle"></i> このコメントを通報しますか？</p>
                <p class="text-muted text-left mb-0">通報対象</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・犯罪やテロ行為を示唆・予告する内容</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・薬物・危険ドラッグの取引に関する内容</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・宗教勧誘を目的とする内容</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・詐欺、金銭を要求するような内容</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・個人を特定できる内容</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・その他不適切な内容</p>
                <p class="text-danger text-left mb-2" style="font-size: 0.6rem;">※上記に該当しない場合の通報は禁止されています</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">スレッド名</p>
                <p class="text-left mb-1">{{title}}</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿者</p>
                <p class="text-left mb-1" style="font-size: 0.8rem;">{{deleteRequest['name']}}</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿日時</p>
                <p class="text-left mb-1" style="font-size: 0.8rem;">{{deleteRequest['created_at']}}</p>
                <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿内容</p>
                <p class="text-left text-danger mb-1 border response" style="font-size: 0.8rem;">{{deleteRequest['text']}}</p>
                <button class="mt-3 mb-2 btn btn-block btn-secondary" @click="sendDeleteRequest()">通報する</button>
            </b-modal>
        </div>
    </div>
</template>

<script>
import "../firebase"
import { getFirestore, doc, collection, setDoc} from 'firebase/firestore'
const db = getFirestore();

export default{
    props : {
        comment: Object, //元コメント + 返信
        power1 : String,
        power2 : String,
        title : String
    },
    data(){
        return{
            deleteRequest: '',
        }
    },
    created : function() {
        // リロード対策
        if(!this.title){
            $router.go('/')
        }
    },
    methods : {
        // 通報コメントidセット
        setDeleteRequest: function(res){
            this.deleteRequest = res
        },
        // 通報
        sendDeleteRequest: function(){
            var Unixdate = String(new Date().getTime())
            var date = new Date()
            var year = String(date.getFullYear())
            var month = String(date.getMonth() + 1 )
            var day = String(date.getDate())
            var posted = String(year + '年' + month + '月' + day + '日')
            setDoc(doc(collection(db, "delete_request"), posted, Unixdate, 'response'), {
                created_at: this.deleteRequest['created_at'],
                title: this.title,
                name: this.deleteRequest['name'],
                text: this.deleteRequest['text'],
            });
            this.deleteRequest = '',
            this.$refs['deleteRequestSP-modal'].hide()
            this.$refs['deleteRequestPC-modal'].hide()
        }
    }
}
</script>

<style scoped>
    #thread{
        background: #EEEEEE;
    }
    .main{
        background: #FFFFFF;
    }
    .response{
        white-space: pre-wrap; /* 改行を出力 */
    }
</style>
