<template>
    <div id="thread" class="thread">
        <!-- PC版 -->
        <div class="d-sm-block d-none pr-5 pl-5">
            <div class="row justify-content-center pt-4">
                <div class="col-md-7">
                    <!-- 概要 -->
                    <div class=" main text-left pt-3 pb-3 pl-3 pr-3 mb-3">
                        <h1 class="mb-0" style="font-size: 1.2rem; font-weight: 700;">{{title}}</h1>
                        <span class="text-muted" style="font-size: 0.8rem;">{{created_at}} </span>
                        <span style="font-size: 0.8rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{response}} </span>
                        <span style="font-size: 0.8rem; font-weight: 500;" class="pr-1">{{category}}</span>
                        <!-- SNSシェア -->
                        <a :href="shareURL_Twitter" target="_blank" class="pl-2">
                            <img src="@/assets/twitter.png" width="3%">
                        </a>
                        <a :href="shareURL_Facebook" target="_blank" class="pl-2">
                            <img src="@/assets/facebook.png" width="3%">
                        </a>
                        <a :href="shareURL_LINE" target="_blank" class="pl-2">
                            <img src="@/assets/line.png" width="3%">
                        </a>
                        <div class="row pr-0 pl-0 mr-0 ml-0 pt-2">
                            <div class="col-6 pr-0 pl-0 img_pc" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(power1_img_url)">
                                <img v-if="power1_img_url" :src="power1_img_url" class="img-fluid border">
                                <img v-if="!power1_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                <p class="mb-0 text-left text-danger" style="font-size: 1.0rem; font-weight: 700;">{{power1}}</p>
                            </div>
                            <div class="col-6 pr-0 pl-0 img_pc" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(power2_img_url)">
                                <img v-if="power2_img_url" :src="power2_img_url" class="img-fluid border">
                                <img v-if="!power2_img_url" src="@/assets/cherry_logo.png" class="img-fluid border">
                                <p class="mb-0 text-left text-primary" style="font-size: 1.0rem; font-weight: 700;">{{power2}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- 統計情報 -->
                    <div class="main pl-3 pr-3 pt-2 pb-2 mb-3">
                        <p class="mb-0" style="font-weight: 700;">統計</p>
                        <div class="row pr-0 pl-0 mr-0 ml-0">
                            <div class="col-3">
                                <p class="mb-0" style="font-size: 0.6rem;">全体コメント数</p>
                                <Graph
                                    :datas="{
                                        'powerA': power1,
                                        'powerB': power2,
                                        'dataA' : power1_res_count,
                                        'dataB' : power2_res_count
                                    }"
                                />
                            </div>
                            <div class="col-3">
                                <p class="mb-0" style="font-size: 0.6rem;">男性コメント数</p>
                                <GraphMale
                                    :datas="{
                                        'powerA': power1,
                                        'powerB': power2,
                                        'dataA' : power1_res_count_M,
                                        'dataB' : power2_res_count_M
                                    }"
                                />
                            </div>
                            <div class="col-3">
                                <p class="mb-0" style="font-size: 0.6rem;">女性コメント数</p>
                                <GraphFemale
                                    :datas="{
                                        'powerA': power1,
                                        'powerB': power2,
                                        'dataA' : power1_res_count_F,
                                        'dataB' : power2_res_count_F
                                    }"
                                />
                            </div>
                            <div class="col-3">
                                <p class="mb-0" style="font-size: 0.6rem;">いいね数</p>
                                <Graph
                                    :datas="{
                                        'powerA': power1,
                                        'powerB': power2,
                                        'dataA' : power1_res_count,
                                        'dataB' : power2_res_count
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 main">
                        <p class="pt-2 pb-2 mb-0" style="font-weight: 700;">年代別コメント数</p>
                        <GraphAge
                            :datas="{
                                'powerA': power1,
                                'powerB': power2,
                                'dataA' : [power1_res_count_10, power1_res_count_20, power1_res_count_30, power1_res_count_40, power1_res_count_50, power1_res_count_60],
                                'dataB' : [power2_res_count_10, power2_res_count_20, power2_res_count_30, power2_res_count_40, power2_res_count_50, power2_res_count_60]
                            }"
                            :height=150
                        />
                    </div>

                    <!-- 新着スレッド -->
                    <div class="main pr-3 pl-3 pt-2 pb-2 mb-3">
                        <NewThread />
                    </div>

                    <!-- 同カテゴリスレッド -->
                    <div class="main pl-3 pr-3 pt-2 pb-2 mb-3">
                        <Similar :id="id" :category="category" />
                    </div>

                    <!-- 本文 -->
                    <div class="main pr-2 pl-2 pt-2 pb-2 mb-3">
                        <div v-for="res in thread" :key="res.number">
                            <div class="text-left border-bottom mb-3">
                                <span style="font-size: 0.8rem;">{{res.number}}.</span>
                                <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{res.name}}</span>
                                <span class="text-muted" style="font-size: 0.8rem;"> {{res.created_at}} </span>
                                <span v-if="res.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                                <span v-if="res.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                                <span v-if="res.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                                <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestMD @click="setDeleteRequest(res)"> [/通報]</span>
                                <!-- 画像 -->
                                <div v-if="res.imgURL" class="img_pc" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(res.imgURL)">
                                    <img :src="res.imgURL" class="img-fluid border">
                                </div>
                                <!-- テキスト -->
                                <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight: 600;">{{res.text}}</p>
                                <!-- 返信・いいね -->
                                <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                                    <div class="col-8 text-left pl-0">
                                        <span v-b-modal.responseMD class="text-muted" style="font-size: 0.9rem;" @click="setResponseTo(res.number)">>>返信する</span>
                                    </div>
                                    <div class="col-2 text-left pl-0">
                                        <div v-if="res.from.length != 0">
                                            <router-link :to="{ name: 'Response', params: {id:id, number:res.number, comment:res, power1:power1, power2:power2, title:title}}">
                                                <span style="font-size: 0.9rem; color: rgb(165, 37, 37);">返信 <i class="bi bi-chat-left-fill"></i> {{res.from.length}}</span>
                                            </router-link>
                                        </div>
                                        <span v-if="res.from.length == 0" class="text-muted" style="font-size: 0.9rem;">返信 <i class="bi bi-chat-left-fill"></i> 0</span>
                                    </div>
                                    <div class="col-2 text-right">
                                        <span class="text-muted" style="font-size: 0.9rem;"><i class="bi bi-hand-thumbs-up-fill"></i> 0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 書き込み -->
                    <div class="main pr-2 pl-2 pt-2 pb-2 mb-3" @click="getResponseCount()">
                        <p class="mt-2 mb-2 text-left" style="font-size: 1.0rem;">コメントを投稿する</p>
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                            <!-- コメント -->
                            <div class="col-8">
                                <p class="mb-0 text-left" style="font-size: 0.8rem;"><i class="bi bi-pencil"></i> コメント</p>
                                <form class="was-validated mb-2">
                                    <textarea class="form-control is-invalid" id="validationTextarea" rows="10" v-model="text" placeholder="コメント" required></textarea>
                                    <p v-if="countText >=0" class="mb-0 text-muted text-left" style="font-size: 0.8rem;">残り{{ countText }}文字</p>
                                    <p v-if="countText < 0" class="mb-0 text-danger text-left" style="font-size: 0.8rem;">文字数が多すぎます</p>
                                </form>
                            </div>
                            <!-- 画像投稿 -->
                            <div class="col-4">
                                <p class="mb-0 text-left" style="font-size: 0.8rem;"><i class="bi bi-image"></i> 画像添付</p>
                                <div class="img_set" @click="imgTap1()">
                                    <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="img_post == null">
                                    <img :src="img_post" alt="img" class="img-fluid border" v-if="img_post!=null">
                                    <input type="file" ref="file1"  style="display: none;" @change="setImage1" accept="image/jpeg, image/jpg, image/png">
                                </div>
                            </div>
                        </div>
                        <div class="mb-2">
                            <p class="mb-0 text-left" style="font-size: 0.8rem;">名前</p>
                            <b-form-input v-model="name" type="text" debounce="500"></b-form-input>
                        </div>
                        <div class="text-left mb-2 border-bottom">
                            <p class="mb-0" style="font-size: 0.8rem;">支持</p>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-power1" name="customRadio" class="custom-control-input" v-model="power" :value="power1">
                                <label class="custom-control-label" for="customRadio-power1">{{power1}}</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-power2" name="customRadio" class="custom-control-input" v-model="power" :value="power2">
                                <label class="custom-control-label" for="customRadio-power2">{{power2}}</label>
                            </div>
                            <div v-if="neutral" class="custom-control custom-radio">
                                <input type="radio" id="customRadio-power-neutral" name="customRadio" class="custom-control-input" v-model="power" value="中立">
                                <label class="custom-control-label" for="customRadio-power-neutral">中立</label>
                            </div>
                        </div>
                        <div class="text-left mb-2 border-bottom">
                            <p class="mb-0" style="font-size: 0.8rem;">性別</p>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-male" name="customRadio-sex" class="custom-control-input" v-model="sex" value="M">
                                <label class="custom-control-label" for="customRadio-male">男性</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-female" name="customRadio-sex" class="custom-control-input" v-model="sex" value="F">
                                <label class="custom-control-label" for="customRadio-female">女性</label>
                            </div>
                        </div>
                        <div class="text-left mb-2 border-bottom">
                            <p class="mb-0" style="font-size: 0.8rem;">年代</p>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-10" name="customRadio-age" class="custom-control-input" v-model="age" value="10">
                                <label class="custom-control-label" for="customRadio-age-10">10代</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-20" name="customRadio-age" class="custom-control-input" v-model="age" value="20">
                                <label class="custom-control-label" for="customRadio-age-20">20代</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-30" name="customRadio-age" class="custom-control-input" v-model="age" value="30">
                                <label class="custom-control-label" for="customRadio-age-30">30代</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-40" name="customRadio-age" class="custom-control-input" v-model="age" value="40">
                                <label class="custom-control-label" for="customRadio-age-40">40代</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-50" name="customRadio-age" class="custom-control-input" v-model="age" value="50">
                                <label class="custom-control-label" for="customRadio-age-50">50代</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio-age-60" name="customRadio-age" class="custom-control-input" v-model="age" value="60">
                                <label class="custom-control-label" for="customRadio-age-60">60代以上</label>
                            </div>
                        </div>
                        <p class="text-danger">{{error_msg}}</p>
                        <button class="pt-2 btn btn-post btn-block" @click="postResponse()">投稿する</button>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="mb-3 main">
                        <Adsense />
                    </div>
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pt-3">
            <!-- タイトル/各種情報 -->
            <div class="main pr-2 pl-2 pt-4 pb-2 mb-3 shadow-sm">
                <div class="text-left">
                    <h1 class="mb-2" style="font-size: 1.2rem; font-weight: 500;">{{title}}</h1>
                    <span class="text-muted" style="font-size: 0.8rem;">{{created_at}} </span>
                    <span style="font-size: 0.8rem; color:rgb(165, 37, 37);"><i class="bi bi-chat-left-dots-fill"></i> {{response}} </span>
                    <span style="font-size: 0.8rem; font-weight: 400;">{{category}}</span><br>
                    <!-- SNSシェア -->
                    <a :href="shareURL_Twitter" target="_blank">
                        <img src="@/assets/twitter.png" width="8%">
                    </a>
                    <a :href="shareURL_Facebook" target="_blank" class="pl-2">
                        <img src="@/assets/facebook.png" width="8%">
                    </a>
                    <a :href="shareURL_LINE" target="_blank" class="pl-2">
                        <img src="@/assets/line.png" width="8%">
                    </a>
                    <div class="row pr-0 pl-0 mr-0 ml-0 pt-2">
                        <div class="col-6 pr-0 pl-0 img_sp" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(power1_img_url)">
                            <img v-if="power1_img_url" :src="power1_img_url" class="img-fluid border">
                            <img v-if="!power1_img_url" src="@/assets/cherry_logo.png" class="img img-fluid border">
                            <p class="mb-0 text-left text-danger" style="font-size: 1.0rem; font-weight: 500;">{{power1}}</p>
                        </div>
                        <div class="col-6 pr-0 pl-0 img_sp" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(power2_img_url)">
                            <img v-if="power2_img_url" :src="power2_img_url" class="img-fluid border">
                            <img v-if="!power2_img_url" src="@/assets/cherry_logo.png" class="img img-fluid border">
                            <p class="mb-0 text-left text-primary" style="font-size: 1.0rem; font-weight: 500;">{{power2}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 統計情報 -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm">
                <p class="mb-0">統計</p>
                <div class="row pr-0 pl-0 mr-0 ml-0 pt-2">
                    <div class="col-6">
                        <p class="mb-0" style="font-size: 0.6rem;">全体コメント数</p>
                        <Graph
                            :datas="{
                                'powerA': power1,
                                'powerB': power2,
                                'dataA' : power1_res_count,
                                'dataB' : power2_res_count
                            }"
                        />
                    </div>
                    <div class="col-6">
                        <p class="mb-0" style="font-size: 0.6rem;">いいね数</p>
                        <Graph
                            :datas="{
                                'powerA': power1,
                                'powerB': power2,
                                'dataA' : power1_res_count,
                                'dataB' : power2_res_count
                            }"
                        />
                    </div>
                </div>
                <div class="row pr-0 pl-0 mr-0 ml-0 pt-2">
                    <div class="col-6">
                        <p class="mb-0" style="font-size: 0.6rem;">男性コメント数</p>
                        <GraphMale
                            :datas="{
                                'powerA': power1,
                                'powerB': power2,
                                'dataA' : power1_res_count_M,
                                'dataB' : power2_res_count_M
                            }"
                        />
                    </div>
                    <div class="col-6">
                        <p class="mb-0" style="font-size: 0.6rem;">女性コメント数</p>
                        <GraphFemale
                            :datas="{
                                'powerA': power1,
                                'powerB': power2,
                                'dataA' : power1_res_count_F,
                                'dataB' : power2_res_count_F
                            }"
                        />
                    </div>
                </div>
                <div class="pt-2">
                    <p class="mb-0" style="font-size: 0.6rem;">年代別コメント数</p>
                    <GraphAge
                        :datas="{
                            'powerA': power1,
                            'powerB': power2,
                            'dataA' : [power1_res_count_10, power1_res_count_20, power1_res_count_30, power1_res_count_40, power1_res_count_50, power1_res_count_60],
                            'dataB' : [power2_res_count_10, power2_res_count_20, power2_res_count_30, power2_res_count_40, power2_res_count_50, power2_res_count_60]
                        }"
                        :height=200
                    />
                </div>
            </div>
            <!-- 新着スレッド -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm">
                <NewThread />
            </div>
            <!-- 同カテゴリスレッド -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm">
                <Similar :id="id" :category="category" />
            </div>
            <!-- 広告 -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm">
                <Adsense /> 
            </div>
            <!-- 本文 -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm">
                <div v-for="res in thread" :key="res.number">
                    <div class="text-left border-bottom mb-3">
                        <span style="font-size: 0.8rem;">{{res.number}}.</span>
                        <span style="font-size: 0.8rem; color: rgb(165, 37, 37);"> {{res.name}}</span>
                        <span class="text-muted" style="font-size: 0.8rem;"> {{res.created_at}} </span>
                        <span v-if="res.power == power1" class="" style="font-size: 0.8rem;"><b-badge variant="danger">{{power1}}</b-badge></span>
                        <span v-if="res.power == power2" class="" style="font-size: 0.8rem;"><b-badge variant="primary">{{power2}}</b-badge></span>
                        <span v-if="res.power == '中立'" class="" style="font-size: 0.8rem;"><b-badge variant="secondary">中立</b-badge></span>
                        <span class="text-muted" style="font-size: 0.8rem;" v-b-modal.deleteRequestMD @click="setDeleteRequest(res)"> [/通報]</span>
                        <!-- 画像 -->
                        <div v-if="res.imgURL" class="img_sp" v-b-modal.deleteRequestImgMD @click="setDeleteRequestImg(res.imgURL)">
                            <img :src="res.imgURL" class="img-fluid border">
                        </div>
                        <!-- テキスト -->
                        <p class="mb-5 mt-2 response" style="font-size: 1.0rem; font-weight:500;">{{res.text}}</p>
                        <!-- 返信・いいね -->
                        <div class="row pr-0 pl-0 mr-0 ml-0 pb-1">
                            <div class="col-6 text-left pl-0">
                                <span v-b-modal.responseMD class="text-muted" style="font-size: 0.9rem;" @click="setResponseTo(res.number)">>>返信する</span>
                            </div>
                            <div class="col-3 text-right pl-0">
                                <div v-if="res.from.length != 0">
                                    <router-link :to="{ name: 'Response', params: {id:id, number:res.number, comment:res, power1:power1, power2:power2, title:title}}">
                                        <span style="font-size: 0.9rem; color: rgb(165, 37, 37);"><i class="bi bi-chat-left-fill"></i> {{res.from.length}}</span>
                                    </router-link>
                                </div>
                                <span v-if="res.from.length == 0" class="text-muted" style="font-size: 0.9rem;"><i class="bi bi-chat-left-fill"></i> 0</span>
                            </div>
                            <div class="col-3 text-right">
                                <span class="text-muted" style="font-size: 0.9rem;"><i class="bi bi-hand-thumbs-up-fill"></i> 0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 書き込み -->
            <div class="main pr-2 pl-2 pt-2 pb-2 mb-3 shadow-sm" @click="getResponseCount()">
                <p class="mt-2 mb-2 text-left" style="font-size: 1.0rem;">コメントを投稿する</p>
                <b-tabs content-class="mt-0">
                    <!-- コメントタブ -->
                    <b-tab title="コメント" active>
                        <p class="mt-2 mb-2 text-left" style="font-size: 0.8rem;"><i class="bi bi-pencil"></i> コメントを記載してください</p>
                        <form class="was-validated mb-2">
                            <textarea class="form-control is-invalid" id="validationTextarea-sp" rows="10" v-model="text" placeholder="コメント" required></textarea>
                            <p v-if="countText >=0" class="mb-0 text-muted text-left" style="font-size: 0.8rem;">残り{{ countText }}文字</p>
                            <p v-if="countText < 0" class="mb-0 text-danger text-left" style="font-size: 0.8rem;">文字数が多すぎます</p>
                        </form>
                    </b-tab>
                    <!-- 画像投稿タブ -->
                    <b-tab title="画像">
                        <p class="mt-2 mb-2 text-left" style="font-size: 0.8rem;"><i class="bi bi-image"></i> 画像をアップロードしてください</p>
                        <div class="img_set" @click="imgTap1()">
                            <img src="@/assets/cherry_logo.png" class="img-fluid border" v-if="img_post == null">
                            <img :src="img_post" alt="img" class="img-fluid border" v-if="img_post!=null">
                            <input type="file" ref="file1"  style="display: none;" @change="setImage1" accept="image/jpeg, image/jpg, image/png">
                        </div>
                    </b-tab>
                </b-tabs>
                <div class=" mt-2 mb-2">
                    <p class="mb-0 text-left" style="font-size: 0.8rem;">名前</p>
                    <b-form-input v-model="name" type="text" debounce="500"></b-form-input>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <p class="mb-0" style="font-size: 0.8rem;">支持</p>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power1-sp" name="customRadio-sp" class="custom-control-input" v-model="power" :value="power1">
                        <label class="custom-control-label" for="customRadio-power1-sp">{{power1}}</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power2-sp" name="customRadio-sp" class="custom-control-input" v-model="power" :value="power2">
                        <label class="custom-control-label" for="customRadio-power2-sp">{{power2}}</label>
                    </div>
                    <div v-if="neutral" class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power-neutral-sp" name="customRadio-sp" class="custom-control-input" v-model="power" value="中立">
                        <label class="custom-control-label" for="customRadio-power-neutral-sp">中立</label>
                    </div>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <p class="mb-0" style="font-size: 0.8rem;">性別</p>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-male-sp" name="customRadio-sex-sp" class="custom-control-input" v-model="sex" value="M">
                        <label class="custom-control-label" for="customRadio-male-sp">男性</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-female-sp" name="customRadio-sex-sp" class="custom-control-input" v-model="sex" value="F">
                        <label class="custom-control-label" for="customRadio-female-sp">女性</label>
                    </div>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <p class="mb-0" style="font-size: 0.8rem;">年代</p>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-10-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="10">
                        <label class="custom-control-label" for="customRadio-age-10-sp">10代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-20-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="20">
                        <label class="custom-control-label" for="customRadio-age-20-sp">20代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-30-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="30">
                        <label class="custom-control-label" for="customRadio-age-30-sp">30代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-40-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="40">
                        <label class="custom-control-label" for="customRadio-age-40-sp">40代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-50-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="50">
                        <label class="custom-control-label" for="customRadio-age-50-sp">50代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-60-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="60">
                        <label class="custom-control-label" for="customRadio-age-60-sp">60代以上</label>
                    </div>
                </div>
                <p class="text-danger">{{error_msg}}</p>
                <button class="mt-3 mb-3 btn btn-post btn-block" @click="postResponse()">投稿する</button>
            </div>
            <br><br><br>
        </div>

        <!-- 返信モーダル -->
        <b-modal id="responseMD" ref="responseMD-modal" class="responseMD" ok-only ok-title="閉じる" ok-variant="secondary" @hidden="resetResponseTo()" hide-header>
            <div @click="getResponseCount()">
                <span class="mb-0 text-left text-danger" style="font-size: 1.0rem;"> >>{{to}}</span>
                <form class="was-validated mb-2">
                    <textarea class="form-control is-invalid" id="validationTextarea-sp" rows="5" v-model="text" placeholder="コメント" required></textarea>
                    <p v-if="countText >=0" class="mb-0 text-muted text-left" style="font-size: 0.8rem;">残り{{ countText }}文字</p>
                    <p v-if="countText < 0" class="mb-0 text-danger text-left" style="font-size: 0.8rem;">文字数が多すぎます</p>
                </form>
                <div class="mb-2">
                    <b-form-input v-model="name" type="text" debounce="500"></b-form-input>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power1-sp" name="customRadio-sp" class="custom-control-input" v-model="power" :value="power1">
                        <label class="custom-control-label" for="customRadio-power1-sp">{{power1}}</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power2-sp" name="customRadio-sp" class="custom-control-input" v-model="power" :value="power2">
                        <label class="custom-control-label" for="customRadio-power2-sp">{{power2}}</label>
                    </div>
                    <div v-if="neutral" class="custom-control custom-radio">
                        <input type="radio" id="customRadio-power-neutral-sp" name="customRadio-sp" class="custom-control-input" v-model="power" value="中立">
                        <label class="custom-control-label" for="customRadio-power-neutral-sp">中立</label>
                    </div>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-male-sp" name="customRadio-sex-sp" class="custom-control-input" v-model="sex" value="M">
                        <label class="custom-control-label" for="customRadio-male-sp">男性</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-female-sp" name="customRadio-sex-sp" class="custom-control-input" v-model="sex" value="F">
                        <label class="custom-control-label" for="customRadio-female-sp">女性</label>
                    </div>
                </div>
                <div class="text-left mb-2 border-bottom">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-10-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="10">
                        <label class="custom-control-label" for="customRadio-age-10-sp">10代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-20-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="20">
                        <label class="custom-control-label" for="customRadio-age-20-sp">20代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-30-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="30">
                        <label class="custom-control-label" for="customRadio-age-30-sp">30代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-40-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="40">
                        <label class="custom-control-label" for="customRadio-age-40-sp">40代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-50-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="50">
                        <label class="custom-control-label" for="customRadio-age-50-sp">50代</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio-age-60-sp" name="customRadio-age-sp" class="custom-control-input" v-model="age" value="60">
                        <label class="custom-control-label" for="customRadio-age-60-sp">60代以上</label>
                    </div>
                </div>
                <p class="text-danger">{{error_msg}}</p>
                <button class="mt-0 mb-0 btn btn-post btn-block" @click="postResponse()">投稿する</button>
            </div>
        </b-modal>

        <!-- コメント削除依頼モーダル -->
        <b-modal id="deleteRequestMD" ref="deleteRequestMD-modal" class="deleteRequestMD" ok-only ok-title="閉じる" ok-variant="secondary" hide-header @hidden="deleteConfirm = ''">
            <p class="text-danger text-center mb-2"><i class="bi bi-exclamation-triangle"></i> このコメントを通報しますか？</p>
            <p class="text-muted text-left mb-0">通報対象</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・犯罪やテロ行為を示唆・予告する内容</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・薬物・危険ドラッグの取引に関する内容</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・宗教勧誘を目的とする内容</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・詐欺、金銭を要求するような内容</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・個人を特定できる内容</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">・その他不適切な内容</p>
            <p class="text-danger text-left mb-2" style="font-size: 0.6rem;">※上記に該当しない場合の通報は禁止されています</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">スレッド名</p>
            <p class="text-left mb-1">{{title}}</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿者</p>
            <p class="text-left mb-1" style="font-size: 0.8rem;">{{deleteRequest['name']}}</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿日時</p>
            <p class="text-left mb-1" style="font-size: 0.8rem;">{{deleteRequest['created_at']}}</p>
            <p class="text-muted text-left mb-0" style="font-size: 0.6rem;">投稿内容</p>
            <p class="text-left text-warning mb-3 border response" style="font-size: 0.8rem;">{{deleteRequest['text']}}</p>
            <p class="text-danger text-left mb-0" style="font-size: 0.6rem;">※確認の為以下の文字を入力してください</p>
            <p class="text-left mb-0" style="font-size: 0.6rem;">{{deleteConfirmWord}}</p>
            <b-form-input v-model="deleteConfirm" type="text" debounce="500"></b-form-input>
            <button v-if="deleteConfirm != deleteConfirmWord" class="mt-3 mb-2 btn btn-block btn-secondary disabled" aria-disabled="true">通報する</button>
            <button v-if="deleteConfirm == deleteConfirmWord" class="mt-3 mb-2 btn btn-block btn-secondary" @click="sendDeleteRequest()">通報する</button>
        </b-modal>

        <!-- 画像削除依頼モーダル -->
        <b-modal id="deleteRequestImgMD" ref="deleteRequestImgMD-modal" class="deleteRequestImgMD" ok-only ok-title="閉じる" ok-variant="secondary" hide-header @hidden="deleteConfirm = ''">
            <p class="text-danger text-center mb-2"><i class="bi bi-exclamation-triangle"></i> この画像を通報しますか？</p>
            <div class="img-sp">
                <img v-if="deleteRequestImg" :src="deleteRequestImg" class="img-fluid border">
            </div>
            <p class="text-danger text-center mb-0" style="font-size: 0.6rem;">通報理由</p>
            <div class="custom-control custom-radio">
                <input type="radio" id="customRadio-img-1" name="customRadio-img" class="custom-control-input" v-model="deleteRequestImgReason" value="著作権・利用規約に反する画像">
                <label class="custom-control-label" for="customRadio-img-1">著作権・利用規約に反する画像</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" id="customRadio-img-2" name="customRadio-img" class="custom-control-input" v-model="deleteRequestImgReason" value="個人情報を含む画像">
                <label class="custom-control-label" for="customRadio-img-2">個人情報を含む画像</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" id="customRadio-img-3" name="customRadio-img" class="custom-control-input" v-model="deleteRequestImgReason" value="過度に性的・グロテスクな内容を含む画像">
                <label class="custom-control-label" for="customRadio-img-3">過度に性的・グロテスクな内容を含む画像</label>
            </div>
            <p class="text-danger text-left mb-0" style="font-size: 0.6rem;">※上記に該当しない場合の通報は禁止されています</p>
            <p class="text-danger text-left mb-0" style="font-size: 0.6rem;">※確認の為以下の文字を入力してください</p>
            <p class="text-left mb-0" style="font-size: 0.6rem;">{{deleteConfirmWord}}</p>
            <b-form-input v-model="deleteConfirm" type="text" debounce="500"></b-form-input>
            <button v-if="deleteConfirm != deleteConfirmWord || !deleteRequestImgReason" class="mt-3 mb-2 btn btn-block btn-secondary disabled" aria-disabled="true">通報する</button>
            <button v-if="deleteConfirm == deleteConfirmWord && deleteRequestImgReason" class="mt-3 mb-2 btn btn-block btn-secondary" @click="sendDeleteRequestImg()">通報する</button>
        </b-modal>
    </div>
</template>

<script>
import Adsense from "@/components/adsense/adsense.vue"
import Graph from "@/components/thread/graph.vue"
import GraphMale from "@/components/thread/graphMale.vue"
import GraphFemale from "@/components/thread/graphFemale.vue"
import GraphAge from "@/components/thread/graphAge.vue"
import Similar from "@/components/thread/similarThread.vue"
import NewThread from "@/components/home/newThread.vue"
import Compressor from 'compressorjs'
import "../firebase"
import { getFirestore, doc, collection, setDoc, getDoc, getDocs} from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const threadRef = collection(db, "thread");
const storage = getStorage();

export default {
    name: 'Thread',
    components: {
        Adsense,
        Graph,
        GraphMale,
        GraphFemale,
        GraphAge,
        Similar,
        NewThread
    },
    props:{
        id : String,
    },
    data(){
        return{
            //スレッド情報
            title: '',
            category: '',
            neutral: false,
            created_at: '',
            power1: '',
            power2: '',
            power1_res_count: 0,
            power2_res_count: 0,
            power1_res_count_M: 0,
            power2_res_count_M: 0,
            power1_res_count_F: 0,
            power2_res_count_F: 0,
            power1_res_count_10: 0,
            power2_res_count_10: 0,
            power1_res_count_20: 0,
            power2_res_count_20: 0,
            power1_res_count_30: 0,
            power2_res_count_30: 0,
            power1_res_count_40: 0,
            power2_res_count_40: 0,
            power1_res_count_50: 0,
            power2_res_count_50: 0,
            power1_res_count_60: 0,
            power2_res_count_60: 0,
            response: 0,
            power1_img_url: '',
            power2_img_url: '',
            //本文全件レス格納用配列
            thread : [],
            //書き込み
            text: '',
            name: '名無しのチェリー',
            sex: 'M',
            age: '20',
            power: '',
            error_msg: '',
            to: 0,
            img_post: null,
            //SNSシェアURL
            shareURL_LINE: '',
            shareURL_Twitter: '',
            shareURL_Facebook: '',
            //通報(コメント・画像・スレッド)
            deleteRequest: '',
            deleteRequestImg: '',
            deleteRequestImgReason: '',
            deleteConfirm: '',
            deleteConfirmWord: 'さくらんぼちゃんねる'
        }
    },
    mounted: function(){
        //propにidが無ければ(リロード時/時間経過後など)vueXから最新の閲覧スレッドidを取得
        if(!this.id){
            this.id = this.$store.getters['history/getRecent']
        }
        this.getThreadInfo()
        this.getThreadText()
        this.getResponseCount()
        this.updateHistory()
        this.createShareURL()
    },
    methods:{
        // スレッド情報取得
        getThreadInfo: async function(){
            const docRef = doc(db, "thread", this.id)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.title = docSnap.data().title
                this.category = docSnap.data().category
                this.neutral = docSnap.data().neutral
                this.created_at = docSnap.data().created_at
                this.power1 = docSnap.data().power1
                this.power2 = docSnap.data().power2
                this.power1_img_url = docSnap.data().power1_img_url
                this.power2_img_url = docSnap.data().power2_img_url
                //[SEO対策]タイトル・説明変更
                document.title = docSnap.data().title + " - さくらんぼちゃんねる"
                var description = docSnap.data().power1 + "と" + docSnap.data().power2 + "を比較討論する" + docSnap.data().category + "カテゴリーのスレッドです"
                document.querySelector('meta[name="description"]').setAttribute("content", description)
                document.querySelector('meta[name="keywords"]').setAttribute("content", docSnap.data().power1 + ',' + docSnap.data().power2)
            } else {
            }
        },
        // スレッドレス数取得
        getResponseCount: async function(){
            const docRef = doc(db, "thread", this.id)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.response = docSnap.data().response
            } else {
            }
        },
        // スレッド本文情報取得 => thread[]に格納 (各勢力レス数もカウント -> graph.vue)
        getThreadText: async function(){
                const querySnapshot = await getDocs(collection(db, "thread", this.id, "response"))
                querySnapshot.forEach((doc) => {
                this.thread.push({
                    created_at : doc.data().created_at,
                    created_unix : doc.data().created_unix,
                    name : doc.data().name,
                    text : doc.data().text,
                    number : doc.data().number,
                    sex : doc.data().sex,
                    age : doc.data().age,
                    power : doc.data().power,
                    to : doc.data().to,
                    from : [],
                    imgURL : doc.data().imgURL,
                })
                // 返信格納
                if(doc.data().to != 0){
                    this.thread[doc.data().to - 1]['from'].push(doc.data())
                }
                // レスカウンター(統計用)
                if(doc.data().power == this.power1){
                    this.power1_res_count = this.power1_res_count + 1
                    if(doc.data().sex == 'M'){
                        this.power1_res_count_M = this.power1_res_count_M + 1
                    } else {
                        this.power1_res_count_F = this.power1_res_count_F + 1
                    }
                    switch(doc.data().age){
                        case '10':
                            this.power1_res_count_10 = this.power1_res_count_10 + 1
                            break;
                        case '20':
                            this.power1_res_count_20 = this.power1_res_count_20 + 1
                            break;
                        case '30':
                            this.power1_res_count_30 = this.power1_res_count_30 + 1
                            break;
                        case '40':
                            this.power1_res_count_40 = this.power1_res_count_40 + 1
                            break;
                        case '50':
                            this.power1_res_count_50 = this.power1_res_count_50 + 1
                            break;
                        case '60':
                            this.power1_res_count_60 = this.power1_res_count_60 + 1
                            break;
                    }
                }
                if(doc.data().power == this.power2){
                    this.power2_res_count = this.power2_res_count + 1
                    if(doc.data().sex == 'M'){
                        this.power2_res_count_M = this.power2_res_count_M + 1
                    } else {
                        this.power2_res_count_F = this.power2_res_count_F + 1
                    }
                    switch(doc.data().age){
                        case '10':
                            this.power2_res_count_10 = this.power2_res_count_10 + 1
                            break;
                        case '20':
                            this.power2_res_count_20 = this.power2_res_count_20 + 1
                            break;
                        case '30':
                            this.power2_res_count_30 = this.power2_res_count_30 + 1
                            break;
                        case '40':
                            this.power2_res_count_40 = this.power2_res_count_40 + 1
                            break;
                        case '50':
                            this.power2_res_count_50 = this.power2_res_count_50 + 1
                            break;
                        case '60':
                            this.power2_res_count_60 = this.power2_res_count_60 + 1
                            break;
                    }
                }
            });
        },
        // スレッド投稿( TODO 投稿時改めてレス番号とip取るようにする)(SP)
        /*
            レス番号 1000 + N
            [課題]レス送信時にスレッドの書き込み数(this.response)を最新に更新したい(getResponseCount)がasync非同期処理完了を待たず送信してしまう -> 僅差で書き込まれた内容を上書きしてしまう
            [対応]暫定的に入力欄に@click="getResponseCount()"をつけて送信直前に更新させている
        */
        postResponse: function(){
            this.error_msg = ''
            //バリデーション(HTMLタグ/SQL)
            /*
                HTMLタグを検出...<div,<span,<br,<meta,<title,<p,<h1,<h2,<h3,<h4,<h5,<h6,<section,<formなど
                SQL文法を検出...SELECT,DELETE,UPDATE,INSERT(大文字のみ)
            */
            if ( this.text.indexOf('<div>') != -1 || this.text.indexOf('<span>') != -1 || this.text.indexOf('<br>') != -1 || this.text.indexOf('<meta>') != -1 || 
                this.text.indexOf('<title>') != -1 || this.text.indexOf('<p>') != -1 || this.text.indexOf('<section>') != -1 || this.text.indexOf('<form>') != -1 || 
                this.text.indexOf('<h1>') != -1 || this.text.indexOf('<h2>') != -1 || this.text.indexOf('<h3>') != -1 || this.text.indexOf('<h4>') != -1 || 
                this.text.indexOf('<h5>') != -1 || this.text.indexOf('<h6>') != -1) {
                this.error_msg = 'この文章は投稿できません'
                return
            }
            if (this.text.indexOf('SELECT') != -1 || this.text.indexOf('DELETE') != -1 || this.text.indexOf('UPDATE') != -1 || this.text.indexOf('INSERT') != -1) {
                this.error_msg = 'この文章は投稿できません'
                return
            }
            //バリデーション(投稿設定)
            if(!this.power){
                this.error_msg = '支持する対象を選択してください'
                return
            }
            if(!this.text){
                this.error_msg = 'コメントを記載してください'
                return
            }
            if(!this.name){
                this.name = '名無しのチェリー'
            }
            var Unixdate = new Date().getTime()
            // レス番号更新( +1 )
            setDoc(doc(threadRef, this.id), {
                response : this.response + 1
            },
                { merge: true }
            );
            // 投稿(id 1001 -> 2000)
            const commentRef = String(1000 + this.response + 1)
            setDoc(doc(threadRef, this.id, 'response', commentRef), {
                created_at: this.postDate(),
                created_unix : Unixdate,
                number : this.response + 1,
                ip: '000',
                name: this.name,
                text: this.text,
                power: this.power,
                sex: this.sex,
                age: this.age,
                imgURL: '',
                to : this.to
            });
            //画像投稿&firestore画像ダウンロード先URL設定
            const files1 = this.$refs.file1;
            //storage参照作成
            const imageRef1 = ref(storage, 'threadImages/' + this.id + '/img_' + commentRef);
            uploadBytes(imageRef1, files1.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setDoc(doc(threadRef, this.id, 'response', commentRef), {
                        imgURL : url,
                    },{ merge: true });
                });
            });
            //投稿後スレ情報・本文を初期化・再取得しページトップへスクロール
            this.$refs.file1 = null
            this.thread = []
            this.power1_res_count = 0
            this.power2_res_count = 0
            this.power1_res_count_M = 0
            this.power2_res_count_M = 0
            this.power1_res_count_F = 0
            this.power2_res_count_F = 0
            this.power1_res_count_10 = 0
            this.power2_res_count_10 = 0
            this.power1_res_count_20 = 0
            this.power2_res_count_20 = 0
            this.power1_res_count_30 = 0
            this.power2_res_count_30 = 0
            this.power1_res_count_40 = 0
            this.power2_res_count_40 = 0
            this.power1_res_count_50 = 0
            this.power2_res_count_50 = 0
            this.power1_res_count_60 = 0
            this.power2_res_count_60 = 0
            this.text = ''
            this.name = '名無しのチェリー'
            this.sex = 'M'
            this.age = '20'
            this.power = ''
            this.to = 0
            this.getThreadInfo()
            this.getThreadText()
            this.getResponseCount()
            this.$refs['responseMD-modal'].hide()
            window.scrollTo(0,0)
        },
        // 日時取得
        postDate: function(){
            var date = new Date()
            var year = String(date.getFullYear())
            var month = String(date.getMonth() + 1 )
            var day = String(date.getDate())
            var hour = String(date.getHours())
            var min = String(date.getMinutes())
            var sec = String(date.getSeconds())
            var millisec = String(date.getMilliseconds())
            var posted = year + '/' + month + '/' + day + '/' + hour + ':' + min + ':' + sec + '.' + millisec
            return posted
        },
        // ipアドレス取得
        getIpAdress: function(){
        },
        // 閲覧履歴更新
        updateHistory: function(){
            this.$store.commit('history/pushHistory', this.id)
        },
        // シェアURL作成
        createShareURL: function(){
            this.shareURL_LINE = "https://social-plugins.line.me/lineit/share?url=" + "https://cherrych.net/thread/" + this.id
            this.shareURL_Twitter = 'https://twitter.com/intent/tweet?text=' + "%20%23さくらんぼちゃんねる" + '&url=' + "https://cherrych.net/thread/" + this.id
            this.shareURL_Facebook = 'http://www.facebook.com/share.php?u=' + "https://cherrych.net/thread/" + this.id
        },
        // 通報コメントidセット
        setDeleteRequest: function(res){
            this.deleteRequest = res
        },
        // 通報画像セット
        setDeleteRequestImg: function(url){
            this.deleteRequestImg = url
        },
        // 通報
        sendDeleteRequest: function(){
            var Unixdate = String(new Date().getTime())
            var date = new Date()
            var year = String(date.getFullYear())
            var month = String(date.getMonth() + 1 )
            var day = String(date.getDate())
            var posted = String(year + '年' + month + '月' + day + '日')
            setDoc(doc(collection(db, "delete_request"), posted, Unixdate, 'response'), {
                id: this.id,
                created_at: this.deleteRequest['created_at'],
                title: this.title,
                name: this.deleteRequest['name'],
                text: this.deleteRequest['text'],
            });
            this.deleteRequest = '',
            this.$refs['deleteRequestMD-modal'].hide()
        },
        // 通報(画像)
        sendDeleteRequestImg: function(){
            var Unixdate = String(new Date().getTime())
            var date = new Date()
            var year = String(date.getFullYear())
            var month = String(date.getMonth() + 1 )
            var day = String(date.getDate())
            var posted = String(year + '年' + month + '月' + day + '日')
            setDoc(doc(collection(db, "delete_request"), posted, Unixdate, 'image'), {
                id: this.id,
                title: this.title,
                url: this.deleteRequestImg,
                reason: this.deleteRequestImgReason
            });
            this.deleteRequestImg = '',
            this.deleteRequestImgReason = '',
            this.$refs['deleteRequestImgMD-modal'].hide()
        },
        // 返信先セット
        setResponseTo: function(to){
            this.to = to
            this.text = '>>' + String(this.to)
        },
        // 返信先初期化
        resetResponseTo: function(){
            this.to = 0
            this.text = ''
        },
        // 画像タップ時(->input->file起動)
        imgTap1: function() {
            this.$refs.file1.click();
        },
        async setImage1() {
            const files = this.$refs.file1;
            const fileImg = files.files[0];
            if (fileImg.type.startsWith("image/")) {
                this.img_post = window.URL.createObjectURL(fileImg);
            }
        },
        // 画像圧縮

    },
    computed:{
        countText: function(){
            return (1000 - (this.text).length)
        },
    },
    //SPAで同コンポーネント間移動
    beforeRouteUpdate (to, from, next) {
        this.id = to.params.id
        this.title = ''
        this.category = ''
        this.neutral = false
        this.created_at = ''
        this.power1 = ''
        this.power2 = ''
        this.power1_res_count = 0
        this.power2_res_count = 0
        this.power1_res_count_M = 0
        this.power2_res_count_M = 0
        this.power1_res_count_F = 0
        this.power2_res_count_F = 0
        this.power1_res_count_10 = 0
        this.power2_res_count_10 = 0
        this.power1_res_count_20 = 0
        this.power2_res_count_20 = 0
        this.power1_res_count_30 = 0
        this.power2_res_count_30 = 0
        this.power1_res_count_40 = 0
        this.power2_res_count_40 = 0
        this.power1_res_count_50 = 0
        this.power2_res_count_50 = 0
        this.power1_res_count_60 = 0
        this.power2_res_count_60 = 0
        this.power1_img_url = ''
        this.power2_img_url = ''
        this.getThreadInfo()
        this.thread = []
        this.getThreadText()
        this.response = 0
        this.to = 0
        this.getResponseCount()
        this.updateHistory()
        this.createShareURL()
        next();
	},
};
</script>

<style scoped>
    #thread{
        background: #EEEEEE;
    }
    .main{
        background: #FFFFFF;
    }
    .btn-post{
    background-color: rgb(165, 37, 37);
    color: white;
    }
    .response{
    white-space: pre-wrap; /* 改行を出力 */
    }
    .img_sp img{
        width: 50vw;
        height: 50vw;
        object-fit: contain;
    }
    .img_pc img{
        width: 25vw;
        height: 25vw;
        object-fit: contain;
    }
    a:link, a:visited, a:hover, a:active {
        text-decoration:none; 
    }
</style>
