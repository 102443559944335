<template>
    <div id="home">
        <!-- PC版 -->
        <div class="d-sm-block d-none">
            <div class="row justify-content-center pt-4">
                <div class="col-md-7">
                    <div class="mt-4 pt-3 pl-5 pr-5 main">
                        <topThread />
                    </div>
                    <div class="mt-4 pt-3 pl-5 pr-5 main">
                        <newThread />
                    </div>
                </div>

                <div class="col-md-3">
                    <Adsense />
                    <div class="pt-3">
                        <CreateThread />
                    </div>
                </div>
            </div>
        </div>

        <!-- SP版 -->
        <div class="d-sm-none d-block pt-3 pb-3">
            <div class="pl-2 pr-2 main shadow-sm">
                <TopThread />
            </div>
            <div class="mt-3 pl-2 pr-2 main shadow-sm">
                <NewThread />
            </div>
            <div class="mt-3 pl-2 pr-2 main shadow-sm">
                <Adsense />
            </div>
            <div class="mt-3 pl-2 pr-2 main">
                <CreateThread />
            </div>
        </div>
    </div>
</template>

<script>
import CreateThread  from "@/components/home/createThread.vue"
import TopThread     from "@/components/home/topThread.vue"
import NewThread     from "@/components/home/newThread.vue"
import Adsense      from "@/components/adsense/adsense.vue"

export default{
    name: 'Home',
    components: {
        CreateThread,
        TopThread,
        NewThread,
        Adsense
    },
    data() {
        return {
        };
    },
    mounted: function(){
        //[SEO対策]タイトル・説明変更
        document.title = "さくらんぼちゃんねる"
        var description = "新開発!2つの対立勢力を可視化しながら議論する全く新しい匿名比較掲示板です。あれとこれ、どっち？賛成？反対？好き？嫌い？なんでも自由に気軽に議論しよう!"
        document.querySelector('meta[name="description"]').setAttribute("content", description)
        document.querySelector('meta[name="keywords"]').setAttribute("content", "さくらんぼちゃんねる,比較掲示板,匿名掲示板")
    }
}
</script>

<style scoped>
    #home{
        background: #EEEEEE;
    }
    .main{
        background: #FFFFFF;
    }
</style>
